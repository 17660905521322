/*
 |-----------------------------------------------------------------------------
 | components/atoms/Logo/Logo.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { FC } from 'react';

// import { ray } from 'node-ray/web';

import * as ILogo from './types';

const Logo: FC<ILogo.IProps> = ({ darkMode, name, utilities = '' }) => {
	// ray('Debug atom Logo:', {
	// 	darkMode: darkMode,
	// 	name: name,
	// 	utilities: utilities,
	// }).red();

	return (
		<svg
			className={`
				${darkMode ? 'fill-neutral-100' : 'fill-neutral-900'}
				${utilities}
			`}
			aria-label={name}
			data-testid="logo"
			role="img"
		>
			<use xlinkHref="/imgs/lgo_sprite.svg#brand"></use>
		</svg>
	);
};

export default Logo;
