/*
 |-----------------------------------------------------------------------------
 | components/layouts/Container/Container.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { FC } from 'react';

// import { ray } from 'node-ray/web';

import * as IContainer from './types';

const Container: FC<IContainer.IProps> = ({
	children,
	isCentered,
	tag,
	tinaField,
	utilities,
	width,
}) => {
	// ray('Debug layout Container:', {
	// 	isCentered: isCentered,
	// 	tinaField: tinaField,
	// 	utilities: utilities,
	// 	width: width,
	// }).orange();

	/* eslint-disable @typescript-eslint/no-explicit-any */
	const Tag: any = tag || 'div';

	return (
		<Tag
			className={`
				mx-auto
				px-8
				w-full
				sm:px-4
				${isCentered ? 'flex justify-center text-center' : ''}
				${width ? width : 'max-w-[1280px]'}
				${utilities ? utilities : null}
			`}
			data-tinafield={tinaField}
		>
			{children}
		</Tag>
	);
};

export default Container;
