/*
 |-----------------------------------------------------------------------------
 | components/molecules/HeaderMenu/HeaderMenu.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { FC, useRef } from 'react';

import { forEach, get, map } from 'lodash';
import Link from 'next/link';
// import { ray } from 'node-ray/web';
import { useRouter } from 'next/router';

import Icon from '@/atoms/Icon';

import * as IHeaderMenu from './types';

const HeaderMenu: FC<IHeaderMenu.IProps> = ({
	darkMode,
	items,
	label,
	utilities = '',
}) => {
	// ray('Debug molecule HeaderMenu:', {
	// 	darkMode: darkMode,
	// 	items: items,
	// 	label: label,
	// 	utilities: utilities,
	// }).blue();

	const router = useRouter();

	const dropdownToggles = useRef([]);

	const classNames = [
		`
			link
			md:after:hidden
			${darkMode ? `after:bg-neutral-100` : `after:bg-neutral-900`}
		`,
		`
			link
			md:after:hidden
			${darkMode ? `after:bg-neutral-100` : `after:bg-neutral-900`}
		`,
	];

	const externalLink = (label, url, utilities) => {
		return (
			<a
				className={`group ${utilities}`}
				href={url}
				rel="external noopener noreferrer"
				target="_blank"
			>
				{label}

				<Icon
					fill="fill-current"
					id="r-link"
					size="xs"
					utilities={`
						external
						inline-block
						ml-2
					`}
				/>
			</a>
		);
	};

	const internalLink = (label, path, utilities) => {
		return (
			<Link href={path || '#'} passHref>
				<a
					className={`
						${utilities}
						${
							get(router, 'asPath') === path
								? 'cursor-default after:scale-x-100 after:visible'
								: 'after:invisible after:scale-x-0'
						}
					`}
					href="jsx-a11y"
				>
					{label}
				</a>
			</Link>
		);
	};

	const toggleDropdown = index => {
		const thisButton = get(dropdownToggles, `current[${index}]`);

		forEach(get(dropdownToggles, 'current'), toggle => {
			if (toggle) {
				if (toggle === thisButton) {
					thisButton.nextSibling.classList.toggle('hidden');

					switch (thisButton.getAttribute('title')) {
						case 'Click to close':
							thisButton.setAttribute('title', 'Click to open');
							break;
						case 'Click to open':
							thisButton.setAttribute('title', 'Click to close');
							break;
					}
				} else {
					toggle.nextSibling.classList.remove('hidden');
					toggle.setAttribute('title', 'Click to open');
				}
			}
		});
	};

	return (
		<ul
			className={`
				flex
				items-center
				justify-between
				md:block
				${utilities}
			`}
			aria-label={label}
			data-testid="headerMenu"
		>
			{map(items, (item, index) => (
				<li
					key={index}
					className={`
						flex-[1 0 auto]
						font-normal
						mx-8
						relative
						text-lg
						md:border-b
						md:border-b-blue-500
						md:border-b-solid
						md:mx-0
						md:py-2
						${darkMode ? `text-neutral-100` : `text-neutral-900`}
					`}
				>
					{get(item, 'url') ? (
						externalLink(
							get(item, 'label'),
							get(item, 'url'),
							classNames[0]
						)
					) : get(item, 'children') ? (
						<>
							<button
								className={`
									after:invisible
									after:scale-x-0
									${classNames[0]}
								`}
								onClick={() => toggleDropdown(index)}
								ref={el =>
									(dropdownToggles.current[index] = el)
								}
								title="Click to open"
							>
								{get(item, 'label')}
							</button>
							<ul
								className={`
									absolute
									hidden
									left-0
									top-full
									w-full
									md:static
									md:visible
								`}
							>
								{map(get(item, 'children'), child => (
									<li
										key={index}
										className={`
											font-normal
											my-2
											text-base
											${darkMode ? 'text-neutral-100' : 'text-neutral-900'}
										`}
									>
										{get(child, 'newTab')
											? externalLink(
													get(child, 'label'),
													get(child, 'url'),
													classNames[1]
											  )
											: internalLink(
													get(child, 'label'),
													get(child, 'path'),
													classNames[1]
											  )}
									</li>
								))}
							</ul>
						</>
					) : (
						internalLink(
							get(item, 'label'),
							get(item, 'path'),
							classNames[0]
						)
					)}
				</li>
			))}
		</ul>
	);
};

export default HeaderMenu;
