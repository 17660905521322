/*
 |-----------------------------------------------------------------------------
 | components/organisms/Header/Header.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { FC } from 'react';

import Link from 'next/link';
import { get } from 'lodash';
// import { ray } from 'node-ray/web';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import Container from '@/layouts/Container';
import Hamburger from '@/atoms/Hamburger';
import Logo from '@/atoms/Logo';
import Navbar from '@/molecules/Navbar';
import { getMenu } from '@/utils/menus';
import { menusSelector } from '@/features/menus';
import { organisationSelector } from '@/features/organisation';

import * as IHeader from './types';

const Header: FC<IHeader.IProps> = ({ darkMode }) => {
	// ray('Debug organism Header:', {
	// 	darkMode: darkMode,
	// }).purple();

	const dispatch = useAppDispatch();

	const menus = useAppSelector(menusSelector);
	const organisation = useAppSelector(organisationSelector);

	const menu = getMenu('header', menus);

	//document.querySelector('.jsContent').classList.add('isBlurred');

	return (
		<header
			className={`
				bg-opacity-25
				py-4
				sticky
				top-0
				z-40
				${darkMode ? 'bg-neutral-900' : 'bg-neutral-100'}
			`}
			itemScope
			itemType="http://schema.org/Header"
		>
			<Container utilities="flex items-center justify-between">
				<Link href="/" passHref>
					<a
						href="jsx-a11y"
						itemScope
						itemType="http://schema.org/Organization"
						rel="home"
						title="Back to Home"
					>
						<Logo
							darkMode={darkMode}
							name={get(organisation, 'name')}
							utilities={`h-[48px] w-[200px]`}
						/>
					</a>
				</Link>

				<Hamburger
					closeLabel="Close menu"
					darkMode={darkMode}
					openLabel="Open menu"
					utilities="hidden md:flex"
				/>

				<Navbar darkMode={darkMode} menu={menu} />
			</Container>
		</header>
	);
};

export default Header;
