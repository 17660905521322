/*
 |-----------------------------------------------------------------------------
 | components/atoms/Icon/Icon.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { FC } from 'react';

// import { ray } from 'node-ray/web';

import * as IIcon from './types';

const Icon: FC<IIcon.IProps> = ({
	fill = 'fill-current',
	id,
	size,
	utilities = '',
}) => {
	// ray('Debug atom Icon:', {
	// 	fill: fill,
	// 	id: id,
	// 	size: size,
	// 	utilities: utilities,
	// }).red();

	const sizes = {
		lg: `h-12 w-12`,
		md: `h-8 w-8`,
		sm: `h-6 w-6`,
		xl: `h-16 w-16`,
		xs: `h-4 w-4`,
	};

	return (
		<svg
			className={`${fill} ${sizes[size]} ${utilities}`}
			aria-hidden="true"
			data-testid="icon"
		>
			<use xlinkHref={`/imgs/icn_sprite.svg#${id}`}></use>
		</svg>
	);
};

export default Icon;
