/*
 |-----------------------------------------------------------------------------
 | components/atoms/Hamburger/Hamburger.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { FC, useEffect, useState } from 'react';

// import { ray } from 'node-ray/web';

import { menuSelector, toggleMenu } from '@/features/menu';
import { useAppDispatch, useAppSelector } from '@/app/hooks';

import * as IHamburger from './types';

const Hamburger: FC<IHamburger.IProps> = ({
	closeLabel,
	darkMode,
	isDisabled,
	openLabel,
	utilities = '',
}) => {
	// ray('Debug atom Hamburger:', {
	// 	closeLabel: closeLabel,
	// 	darkMode: darkMode,
	// 	isDisabled: isDisabled,
	// 	openLabel: openLabel,
	// 	utilities: utilities,
	// }).red();

	const dispatch = useAppDispatch();
	const menuState = useAppSelector(menuSelector);

	const [isClicked, setIsClicked] = useState<boolean>(false);
	const [label, setLabel] = useState<string>(openLabel);

	useEffect(() => {
		if (!menuState) {
			setIsClicked(false);
		}

		document.addEventListener('keyup', e => {
			if (e.key === 'Escape') {
				dispatch(toggleMenu(false));
				setIsClicked(false);
				setLabel(closeLabel);
			}
		});
	}, [closeLabel, dispatch, menuState]);

	return (
		<button
			className={`
				flex
				h-11
				items-center
				justify-center
				w-11
				${utilities}
				disabled:opacity-25
			`}
			aria-label={label}
			data-testid="hamburger"
			disabled={isDisabled}
			onClick={() => {
				dispatch(toggleMenu(!menuState));
				setIsClicked(!isClicked);
				setLabel(isClicked ? closeLabel : openLabel);
			}}
			type="button"
		>
			<div
				className={`
					flex
					flex-col
					items-center
					justify-center
					after:block
					after:h-1
					after:rounded
					after:transition-transform
					after:w-9
					before:block
					before:h-1
					before:rounded
					before:transition-transform
					before:w-9
					${
						darkMode
							? `after:bg-neutral-100 before:bg-neutral-100`
							: `after:bg-neutral-900 before:bg-neutral-900`
					}
					${
						isClicked
							? `
								after:-rotate-45
								after:translate-y-0
								before:rotate-45
								before:translate-y-[4px]
							`
							: `
								after:rotate-0
								after:translate-y-[4px]
								before:rotate-0
								before:-translate-y-[4px]
							`
					}
				`}
			>
				<span
					className={`
						block
						h-1
						rounded
						w-9
						${darkMode ? `bg-neutral-100` : `bg-neutral-900`}
						${isClicked ? `hidden` : ''}
					`}
				></span>
			</div>
		</button>
	);
};

export default Hamburger;
